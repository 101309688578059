<template>
  <div>
    <b-card-code title="">
      <div class="row mb-2">
        <div class="col-lg-6">
          <h4>Dokumentumok</h4>
        </div>
        <div class="col-lg-6 text-right">
          <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/project/list/documents/'+$route.params.id)">Dokumentumok</button>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: Status -->
          <!--<span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>-->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'date'">
            {{ props.row.date }}
          </span>
          <span v-else-if="props.column.field === 'document_name'">
            <span v-if="(props.row.type === 'certificate_of_completion' && props.row.data.tig_number !== -1)">{{props.row.data.tig_number}}. teljesítési igazolás</span>
            <span v-if="(props.row.type === 'certificate_of_completion' && props.row.data.tig_number === -1)">Végszámla</span>
            <span v-if="props.row.type === 'contract'">Alvállalkozói építési vállalkozási szerződés</span>
            <span v-if="props.row.type === 'building_contract'"> {{props.row.data.change_number}}.számú szerződés módosítás</span>
            <span v-if="props.row.type === 'individual_tax'"> Kisadózó nyilatkozat</span>
            <span v-if="props.row.type === 'contract_attachment'"> Szerződés melléklet</span>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="archive(props.row.id)">
                  <feather-icon
                    icon="FolderIcon"
                    class="mr-50"
                  />
                  <span>Visszaállítás</span>
                </b-dropdown-item>
                <b-dropdown-item @click="downloadDocument(props.row.id)">
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Letöltés</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mutasd 1-től
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      monolitOptions: [
        { value: 'ME', text: 'Monolit Épszer' },
        { value: 'MP', text: 'Monolit Profi KFT' },
      ],
      subcontractorOptions: [],
      typeOptions: [
        { value: 'contract', text: 'Szerződés' },
        { value: 'individual_tax', text: 'Kisadózó nyilatkozat' },
        { value: 'building_contract', text: 'Szerződés módosítás' },
        { value: 'certificate_of_completion', text: 'Teljesítés igazolás' },
      ],
      type: 'Szerződés',
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Szerződés típusa',
          field: 'document_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés munkaszám',
          },
        },
        {
          label: 'Alvállalkozó',
          field: 'subcontractor_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Kiállítás dátuma',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megrendelők között',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      workOptions: [
        { label: 'betonacél szerelés', value: 'betonacél szerelés' },
        { label: 'beton szállítás', value: 'beton szállítás' },
        { label: 'betonacél szállítás', value: 'betonacél szállítás' },
        { label: 'földmunka', value: 'földmunka' },
        { label: 'komplett szerkezetépítés', value: 'komplett szerkezetépítés' },
        { label: 'kőműves munkák', value: 'kőműves munkák' },
        { label: 'szerkezetépítés', value: 'szerkezetépítés' },
        { label: 'vízszigetelés', value: 'vízszigetelés' },
        { label: 'zsalu bontás és takarítás', value: 'zsalu bontás és takarítás' },
        { label: 'gépészet', value:'gépészet'}
      ],
      form: {
        work: '',
        work_number: '',
        subject_of_the_contract: '',
        building_permit_number: '',
        tax: '',
        monolit: '',
        subcontractor: '',
        site_handover_date: '',
        committed_completion_date: '',
        payment_deadline: '',
        date_of_contract: '',
      },
      rows: [],
      searchTerm: '',
      project_data: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getDocuments()
  },
  methods: {
    archive(id) {
      axios({
        method: 'put',
        url: `project/document/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    getDocuments() {
      axios({
        method: 'get',
        
        url: `project/document/list/archive/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
    downloadDocument(id) {
      axios({
        method: 'post',
        
        url: `project/document/create/contract/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        responseType: 'blob',
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.project_data.name}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
